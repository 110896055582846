<div class="row full-height" *ngIf="!unsubscribe else un_sub_page">
    <div class="col-lg-6 col-md-12 col-sm-12 log-l-side">
        <div class="d-flex justify-content-center flex-column full-height p-b-35">
            <div class="text-center mt-5" id="bg-image-ref">
                <img 
                [src]="randomImage" 
                alt="logo"
                pgRetina
                [src1x]="randomImage"
                [src2x]="randomImage"
                class="mr-3 absolute-center w-56"
              />
            </div>
        </div>
    </div>
    <div class="col-lg-6 col-md-12 col-sm-12 log-r-side">
        <div class="d-flex justify-content-center flex-column full-height p-b-35">
            <div class="text-center h-25 pt-4 md-responsive" id="bg-image-ref">
                <!-- <img *ngIf="!advPro" src="assets/img/planpro_stacked_color_250.png" alt="logo" pgRetina src1x="assets/img/planpro_stacked_color_250.png" 
                src2x="assets/img/planpro_stacked_color_250.png" height="auto" width="200px" class="mr-3"> -->
                <img src="assets/img/advizorpro_stacked_color_250.png" alt="logo" pgRetina src1x="assets/img/advizorpro_stacked_color_250.png" src2x="assets/img/advizorpro_stacked_color_250.png" height="auto" width="200px" class="mr-3">
            </div>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<ng-template #un_sub_page>
    <div class="row justify-content-center full-height">
        <div class="d-flex justify-content-center flex-column full-height p-b-35">
            <div class="text-center mt-5" id="bg-image-ref">
                <img src="assets/img/advizorpro_stacked_color_250.png" alt="logo" pgRetina src1x="assets/img/advizorpro_stacked_color_250.png" src2x="assets/img/advizorpro_stacked_color_250.png" class="mr-3" width="200px">
            </div>
            <router-outlet></router-outlet>
        </div>
    </div>
</ng-template>