import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthLayoutComponent implements OnInit {

  @ViewChild('root') root;

  unsubscribe = false;
  advPro = false;

   // Array of SVG paths
   loginImages: string[] = [
    'assets/img/login/bt_dataset.svg',
    /*
    'assets/img/login/401(k)_plans_dataset.svg',
    'assets/img/login/ai_search.svg',
    'assets/img/login/family_office_dataset.svg',
    'assets/img/login/insurance_dataset.svg',
    'assets/img/login/lead_scoring.svg',
    'assets/img/login/map_view.svg',
    'assets/img/login/private_wealth_teams_dataset.svg'
    */
  ];

   // Will hold the randomly chosen image path
   randomImage: string;


  constructor(
    public router: Router,
  ) { }

  ngOnInit() {
    let url = this.router.url.split('/');
    if (url && url[1] == 'unsubscribe') {
      this.unsubscribe = true;
    }


    let url_ap = this.router.url.split('?');
    if (url_ap && url_ap[1] == 'm=ap') {
      this.advPro = true;
    }

    const randomIndex = Math.floor(Math.random() * this.loginImages.length);
    this.randomImage = this.loginImages[randomIndex];
  }

}
