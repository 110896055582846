import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse } from '@angular/common/http';
import { catchError, filter, take, finalize, switchMap } from 'rxjs/operators';
import { Auth } from './auth.service';
import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { Helper } from './../../shared/helper';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private authService: Auth, private router: Router, private helper: Helper) { }

  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {
    const token = this.authService.getToken()
    const skipIntercept = request.headers.has('skip');

    if (skipIntercept) {
      request = request.clone({
        headers: request.headers.delete('skip')
      });
      return next.handle(request);
    }

    if (token != undefined && token != null && token != '' && token != 'null') {
      return next.handle(this.addTokenToRequest(request, this.authService.getToken()))
        .pipe(
          catchError(err => {
            if (err instanceof HttpErrorResponse) {
              switch ((<HttpErrorResponse>err).status) {
                case 422:
                case 403: {
                  <any>this.authService.logout();
                  return this.router.navigate(['/login']);
                }
                case 401:
                  //if the notifications api timesout, send the user to the login page
                  if (request.url.includes('user/notifications')) {
                    <any>this.authService.logout();
                    window.location.href = `${window.location.origin}/login`
                  } else {
                    return this.handle401Error(request, next);
                  }
                case 400:
                case 500:
                  return throwError(err);
              }
            } else {
              return throwError(err);
            }
          }));
    } else {
      const httpsReq = request.clone({
        url: this.helper.apiUrl(request.url),
      });
      return next.handle(httpsReq);
    }
  }

  private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` }, url: this.helper.apiUrl(request.url) });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      return this.authService.refreshToken()
        .pipe(
          switchMap((user: any) => {
            if (user) {
              this.isRefreshingToken = false;
              this.tokenSubject.next(user.access_token);;
              return next.handle(this.addTokenToRequest(request, user.access_token));
            }

            <any>this.authService.logout();
            return this.router.navigate(['/login']);
          }),
          catchError(() => {
            <any>this.authService.logout();
            return this.router.navigate(['/login']);
          }),
          finalize(() => {
            this.isRefreshingToken = false;
          })
        );
    } else {
      this.isRefreshingToken = false;

      return this.tokenSubject
        .pipe(filter(token => token != null),
          take(1),
          switchMap(token => {
            return next.handle(this.addTokenToRequest(request, token));
          }));
    }
  }
}