import { HttpClient, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { tap, shareReplay, finalize } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AdvizorProService {
    paginationData: {};
    search: {};
    public _subject = new Subject<object>();
    public event = this._subject.asObservable();

    public _advMenu = new Subject<object>();
    public advMenu = this._advMenu.asObservable();

    public _firmContact = new Subject<object>();
    public firmContact = this._firmContact.asObservable();

    public _advSetting = new Subject<object>();
    public advevent = this._advSetting.asObservable();

    public searchData = new Subject<Record<string, unknown>>();
    public searchData$ = this.searchData.asObservable();

    private _advTooltip: BehaviorSubject<Record<string, unknown>>;
    private dataSource: { advTooltip: Record<string, unknown> } = {
        advTooltip: {}
    }

    public _savedSearchChange = new Subject<boolean>();
    public savedSearchChange$ = this._savedSearchChange.asObservable();

    private readonly openSearchSubject = new BehaviorSubject<boolean>(false);
    openSearchSubject$ = this.openSearchSubject.asObservable();

    private tooltipRequest: Observable<{ data: Record<string, unknown> }> | null = null;

    constructor(private http: HttpClient) {
        this._advTooltip = new BehaviorSubject(this.dataSource.advTooltip);
    }

    public advSetting(status: boolean) {
        this._advSetting.next({ status });
    }

    public savedSearchChange(status: boolean) {
        this._savedSearchChange.next(status);
    }

    public publish(data: object) {
        this._subject.next(data);
    }

    public setAdvMenu(data: object) {
        this._advMenu.next(data);
    }

    public setFirmContact(data: object) {
        this._firmContact.next(data);
    }

    public get $advTooltip(): Observable<Record<string, unknown>> {
        return this._advTooltip.asObservable();
    }

    public get advTooltip(): Record<string, unknown> {
        return this.dataSource.advTooltip;
    }

    public set advTooltip(value: Record<string, unknown>) {
        this.dataSource.advTooltip = value;
        this._advTooltip.next(Object.assign({}, this.dataSource).advTooltip);
    }

    getFirmProfile(firm_id: string | number) {
        return this.http.get('/api/advisor/firm-profile/'+firm_id);
    }

    getFirmProfileByType(type: string, personId: number | string){
        return this.http.get(`/api/advisor/${type}/firm/${personId}`)
    }

    getFamilyFirmProfile(firm_id: string | number) {
        return this.http.get('/api/advisor/family-firm/'+firm_id);
    }

    getBankAndTrustFirmProfile(firm_id: string | number) {
        return this.http.get('/api/advisor/bank-trust-firm/'+firm_id);
    }

    getBankAndTrustPersonProfile(bt_id: string | number) {
        return this.http.get('/api/advisor/bank-trust-person/'+bt_id);
    }

    getPersonProfileByType(type: string, id: string | number){
        return this.http.get(`/api/advisor/${type}/person/${id}`)
    }

    getFamilyPersonProfile(firm_id: string | number) {
        return this.http.get('/api/advisor/family-person/'+firm_id);
    }

    searchFirm(data: Record<string, unknown>) {
        return this.http.post('/api/advisor/searchFirm', data);
    }

    searchPerson(data: Record<string, unknown>) {
        return this.http.post('/api/advisor/searchPerson', data);
    }

    getSearchFilter() {
        return this.http.get('/api/advisor/search-filter');
    }

    getWebSearchFilter() {
        return this.http.get('/api/advisor/web-search-filter');
    }

    getCity(data: Record<string, unknown>) {
        return this.http.post('/api/advisor/city', data);
    }

    getCompany(data: Record<string, unknown>) {
        return this.http.post('/api/advisor/company', data);
    }
    
    getSuggestedPrompts(data: { query: string }) {
        return this.http.get('/api/prompts/get-suggested-prompts', {params: new HttpParams().set('query', data.query)}); 
    }

    getPersonProfile(person_id: string | number) {
        return this.http.get('/api/advisor/person/' + person_id);
    }

    getInsurancePersonProfile(person_id: string | number) {
        return this.http.get('/api/advisor/insurance-person/' + person_id);
    }

    getPersonFirmHistory(data: Record<string, unknown>) {
        return this.http.post('/api/advisor/person/firm-history', data);
    }

    getPersonInsuranceCarriers(data: Record<string, unknown>) {
        return this.http.post('/api/advisor/person/insurance-carriers', data);
    }

    getPersonInsuranceLicenses(data: Record<string, unknown>) {
        return this.http.post('/api/advisor/person/insurance-licenses', data);
    }    

    getPersonInsuranceLines(data: Record<string, unknown>) {
        return this.http.post('/api/advisor/person/insurance-lines', data);
    }   

    getPeopleMoves(extraData: Record<string, unknown>) {
        return this.http.post('/api/advisor/people-moves', extraData);
    }
    
    getAdvisorBreakaway(extraData: Record<string, unknown>) {
        return this.http.post('/api/advisor/advisor-breaks', extraData);
    }

    getAdvisorPerson(extraData: { [key: string]: unknown }) {
        return this.http.post('/api/advisor/advisor-person', extraData);
    }

    getAdvisorFirm(extraData: { [key: string]: unknown }) {
        return this.http.post('/api/advisor/advisor-firm', extraData);
    }

    getFirmUpdates(type, limit) {
        return this.http.get(`/api/advisor/${type}`, { params: { limit: limit }});
    }

    addNote(data: Record<string, unknown>, type: string) {
        return this.http.post('/api/advisor/'+type+'/note', data);
    }

    updateNote(data, id) {
        return this.http.put('/api/advisor/note/' + id, data);
    }

    getNotes(profile_id, type) {
        return this.http.get('/api/advisor/'+type+'/notes/' + profile_id,);
    }
    
    getNotesById(extraData: { per_page: number; current_page: number }, profile_id: string | number, type: string) {
        return this.http.get('/api/advisor/'+type+'/getNotes/'+ profile_id, { params: { limit: extraData.per_page, page: extraData.current_page} });
    }

    deleteNote(id) {
        return this.http.delete('/api/advisor/note/' + id);
    }

    addTask(data: Record<string, unknown>, type: string) {
        return this.http.post('/api/advisor/'+type+'/task', data);
    }

    updateTask(data, id) {
        return this.http.put('/api/advisor/task/' + id, data);
    }

    getTasks(profile_id,type) {
        return this.http.get('/api/advisor/'+type+'/tasks/' + profile_id);
    }

    deleteTask(id) {
        return this.http.delete('/api/advisor/task/' + id);
    }

    activityRecord(data: Record<string, unknown>, id: string | number) {
        return this.http.post('/api/advisor/firm/thirdparty/'+id, data);
    }

    followAdvizor(data) {
        return this.http.post('/api/advisor/follow', data);
    }

    unfollowAdvizor(id) {
        return this.http.get('/api/advisor/unfollow-advizor/'+id);
    }

    followStatus(crd ,type) {
        return this.http.get('/api/advisor/'+type+'/follow-status/'+crd);
    }

    getFollowed(extraData: { per_page: number; current_page: number }, data: Record<string, unknown> = {}, sortField: string = '', sortOrder: string = '') {
        return this.http.post('/api/advisor/followed?', { with: data }, { params: { limit: extraData.per_page, page: extraData.current_page, sortField: sortField, sortOrder: sortOrder } });
    }

    firmContacts(data: Record<string, unknown>) {
        return this.http.post('/api/advisor/firm-contact', data);
    }

    firmOwners(data: Record<string, unknown>) {
        return this.http.post('/api/advisor/firm-owners', data);
    }

    dbaContact(data: Record<string, unknown>) {
        return this.http.post('/api/advisor/firm-dba-contact', data);
    }

    firmDba(data: Record<string, unknown>) {
        return this.http.post('/api/advisor/firm-dba', data);
    }

    firmOffice(data: Record<string, unknown>) {
        return this.http.post('/api/advisor/firm-office', data);
    }

    firmHolding(data: Record<string, unknown>) {
        return this.http.post('/api/advisor/firm-holding', data);
    }

    firmHoldingPieChart(data: Record<string, unknown>){
        return this.http.post('/api/charts/mstar-firm-holding-pie-chart', data);
    }

    firmLicensed(data: Record<string, unknown>) {
        return this.http.post('/api/advisor/firm-licensed', data);
    }

    personTeam(data: Record<string, unknown>) {
        return this.http.post('/api/advisor/person-team', data);
    }

    contactExport(data: { crd: string; search: string }) {
        return this.http.post('/api/advisor/contact-export', data);
    }

    teamExport(data: { [key: string]: string }) {
        return this.http.post('/api/advisor/team-export', data);
    }

    officeExport(data: { crd: string }) {
        return this.http.post('/api/advisor/office-export', data);
    }

    saveExportCheckBox(data) {
        return this.http.post('/api/user/advisor-export-checkbox', data);
    }

    getExportCheckBox() {
        return this.http.get('/api/user/advisor-export-checkbox');
    }

    getBrochures(crd) {
        return this.http.get('/api/advisor/advisor-firm-brochure/' + crd);
    }
    
    firmtInsight(id) {
        return this.http.get('/api/advisor/firm/insights/' + id);
    }

    personInsight(id) {
        return this.http.get('/api/advisor/person/insights/' + id);
    }

    recipientCount(data: { [key: string]: unknown }) {
        return this.http.post('/api/advisor/searchPerson', data);
    }

    getPersonRegistered(person_id: string | number, type: string, extraData: { per_page: number; current_page: number }) {
        return this.http.get(`/api/advisor/person/${person_id}/registrations/${type}`, { params: { limit: extraData.per_page, page: extraData.current_page } });
    }
    
    getIssuerName(data) {
        return this.http.post('/api/advisor/issuer-name', data);
    }
    
    getClass(data) {
        return this.http.post('/api/advisor/title-class', data);
    }

    getTicker(data) {
        return this.http.post('/api/advisor/ticker', data);
    }

    savedSearch(save_id = 0) {
        return this.http.get('/api/advisor/saved-search', { params: { id: save_id }});
    }

    saveSearchNew(extraData: { per_page: number; current_page: number }, id: number) {
        return this.http.get('/api/advisor/saved-search?', { params: { limit: extraData.per_page, page: extraData.current_page, id: id } });
    }

    getMetroArea(data) {
        return this.http.post('/api/advisor/metro-area', data);
    }

    getWealthCreator(data) {
        return this.http.post('/api/advisor/wealth-creator', data);
    }
    
    getExportCreditsCount() {
        return this.http.get('/api/user/exports/count');
    }

    getSavedSeachDiff(data: { searchId: number; date?: string }) {
        return this.http.post('/api/advisor/saved-search-diff', data);
    }
    
    insCarriersAutocomplete(data) {
        return this.http.post('/api/advisor/ins-carrier-autocomplete', data);
    }
    
    insCompanyAutocomplete(data) {
        return this.http.post('/api/advisor/ins-company-autocomplete', data);
    }

    getCrossSearch(data: object) {
        return this.http.post('/api/advisor/cross-search', data);
    }

    getCrossSearchStatus(hash) {
        return this.http.get(`/api/advisor/cross-search/status/${hash}`);
    }
    
    getPersonInsuranceAppointments(data: object) {
        return this.http.post('/api/advisor/person/insurance-appointments', data);
    }

    uploadXlsFile(data: FormData) {
        return this.http.post('/api/advisor/match/file', data);
    }

    getPresignedurl(name) {
        return this.http.get('/api/advisor/match/presigned-upload/'+name);
    }

    getUploadMatchList(id) {
        return this.http.get('/api/advisor/match/status/' + id);
    }

    getTooltip(): Observable<Record<string, unknown>> {
        if (Object.keys(this.dataSource.advTooltip).length > 0) {
            return this._advTooltip.asObservable();
        }

        if (this.tooltipRequest) {
            return this.tooltipRequest;
        }

        this.tooltipRequest = this.http.get<{ data: Record<string, unknown> }>('/api/advisor/search-tooltip').pipe(
            tap((response) => {
                this.dataSource.advTooltip = response.data;
                this._advTooltip.next(response.data);
            }),
            shareReplay(1),
            finalize(() => {
                this.tooltipRequest = undefined;
            })
        );

        return this.tooltipRequest;
    }

    getTooltipCategory(data: Record<string, unknown>) {
        return this.http.post('/api/advisor/search-tooltip-category', data);
    }

    getSearchAccess(type: string) {
        return this.http.get('/api/advisor/search-access/'+type);
    }

    firmDiff(firm_id: string | number) {
        return this.http.get('/api/advisor/advisor-firm-diff/'+firm_id);
    }

    saveAdvisorSync(type, data: object) {
        return this.http.post(`/api/integration/search/sync/${type}`, data);
    }

    saveAdvisorAsync(type: string, data: object) {
        return this.http.post(`/api/integration/search/sync/${type}`, data);
    }

    getAdvisorAsyncStatus(id) {
        return this.http.get(`/api/integration/search/sync/status/${id}`);
    }

    getAdvisorSyncStatus(id) {
        return this.http.get(`/api/integration/import-data/status/${id}`);
    }

    uploadfileAWSS3(fileuploadurl, contenttype, file) { 
        const headers = new HttpHeaders({ skip: 'true', 'Content-Type': contenttype});
        const req = new HttpRequest(
          'PUT',
          fileuploadurl,
          file,
          {
            headers: headers,
          });
        return this.http.request(req);
    }

    aiSupportChat(data: {
        prompt?: string;
        assistant_type?: string;
        thread_id?: string;
        run_id?: string;
        type?: string;
      }) {
        return this.http.post('/api/advisor/ai-support-chat', data);
    }

    aiFilters(data: {
        prompt?: string;
      }) {
        return this.http.post('/api/advisor/ai-filters', data);
    }

    changeOpenSearchStatus(status: boolean) {
        this.openSearchSubject.next(status);
    }
}
