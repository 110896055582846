import { Component, Input, SimpleChanges } from '@angular/core';
import { AdvizorProService } from '../../../services/advizor-pro.service';
import type { EChartsOption } from 'echarts';
import { use } from 'echarts/core';
import { TreemapChart } from 'echarts/charts';
import { TooltipComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { MorningStarPieChartData, pieChartFirmHoldingData } from './interface';

@Component({
  selector: 'morning-star-pie-chart',
  templateUrl: './morning-star-pie-chart.component.html',
  styleUrls: ['./morning-star-pie-chart.component.scss'],
})
export class MorningStarPieChartComponent {
  @Input() holding_date: number;
  @Input() crd: number; 
  loading = true
  options: EChartsOption = {}
  constructor(public advizorProService: AdvizorProService,){
    use([TreemapChart, TooltipComponent, CanvasRenderer]);
  }

  ngOnInit(){
    this.options = MorningStarPieChartComponent.chartBuilder([])
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( (changes.crd || changes.holding_date) && this.crd && this.holding_date )
      this.getFirmHoldingStats();
  }

  // static chartBuilder(data: MorningStarPieChartData[]): EChartsOption{
  //   return {
  //     tooltip: {
  //       trigger: 'item',
  //       formatter: (params) => MorningStarPieChartComponent.popupbuilder(params)
  //     },
  //     legend: {
  //       orient: 'horizontal',  
  //       left: 'center',       
  //       bottom: '0%',         
  //       data: data.map(item => item.name)
  //     },
  //     series: [
  //       {
  //         type: 'pie',
  //         radius:  '50%',
  //         avoidLabelOverlap: true,
  //         center: ['50%', '40%'],
  //         label: {
  //           show: true,
  //           formatter: (params) => MorningStarPieChartComponent.selectorBuilder(params)
  //         },
  //         emphasis: {
  //           label: {
  //             show: true,
  //             fontSize: '14',
  //             fontWeight: 'bold'
  //           }
  //         },
  //         data: data as echarts.PieSeriesOption['data']
  //       }
  //     ]
  //    };
  // }

  static chartBuilder(data: MorningStarPieChartData[]): EChartsOption {
    return {
      tooltip: {
        trigger: 'item',
        formatter: (params) => MorningStarPieChartComponent.popupbuilder(params),
      },
      series: [
        {
          type: 'treemap',
          label: {
            show: true,
            formatter: (params) => MorningStarPieChartComponent.selectorBuilder(params),
          },
          levels: [
          ],
          roam: true,
          scaleLimit: {
            min: 1,
          },
          data: data.map((item) => ({
            name: item.name,
            value: Number(item.value), 
            numETF: item.numETF,
            totalShares: item.totalShares,
            percentage: item.percentage
          })),
        },
      ],
    };
  }
  
  static selectorBuilder(params): string{
    return ` ${params.name}: ${MorningStarPieChartComponent.formatMoney(params.value, 0)}`;
  } 

  static popupbuilder(params): string{
    return `
    <div style="min-width: 200px">
      <div style="
        font-weight: bold;
        margin-bottom: 8px;
        color: #666;
        font-size: 14px;
      ">
        ${params.name}
      </div>
      <div style="
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;
      ">
        <span style="font-weight: 500"># of ETFs invested in:</span>
        <span>${params.data.numETF}</span>
      </div>
      <div style="
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;
      ">
        <span style="font-weight: 500"> # of Shares:</span>
        <span>${MorningStarPieChartComponent.formatStringNumber(params.data.totalShares)}</span>
      </div>
      <div style="
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;
      ">
        <span style="font-weight: 500"> Value ($):</span>
        <span>${MorningStarPieChartComponent.formatMoney(params.value)}</span>
      </div>
      <div style="
        display: flex;
        justify-content: space-between;
      ">
        <span style="font-weight: 500">% of portfolio ($):</span>
        <span>${params.data.percentage}%</span>
      </div>
    </div>
  `;
  }
  
  getFirmHoldingStats(): void {
    if(this.crd && this.holding_date){
      this.loading = true;
      const searchHoldingPieChart = {
        crd: this.crd,
        holding_date: this.holding_date,
      };
      this.advizorProService.firmHoldingPieChart(searchHoldingPieChart).subscribe(
          (data: pieChartFirmHoldingData) => {
              if (data) {
                  
                  this.options = MorningStarPieChartComponent.chartBuilder(data.data);
                  this.loading = false;
              }
          },
      );
    }
  }

  static formatMoney = (amount: number, decimals = 2): string => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    }).format(amount);
  }

  static formatStringNumber = (value: string): string => {
    const number = Number(value);
    if (isNaN(number)) return '0';
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

}
