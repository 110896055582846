import { BehaviorSubject, finalize, Observable, shareReplay, Subject, tap } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    paginationData: {};

    private _profile: BehaviorSubject<any>
    private _features: BehaviorSubject<any>
    private _plan_redflags: BehaviorSubject<any>
    private _providers: BehaviorSubject<any>
    private _years: BehaviorSubject<any>
    /* private _savings: BehaviorSubject<any>
    private _plan_redflagCodes: BehaviorSubject<any> */

    private dataSource: { profile: any, features: any, plan_redflags: any, providers: any, years: any };

    features: any;
    plan_redflags: any;
    providers: any;
    years: any;
    /* savings: any;
    plan_redflagCodes: any; */
    private _export: Subject<any>;
    private exportSource: { export: any };

    private _fee_sort: Subject<any>;
    private feeSortSource: { fee_sort: any };

    renderChart = new Subject();
    private planYearRequest: Observable<{ data: Record<string, unknown> }> | null = null;

    constructor(private http: HttpClient) {
        this.dataSource = {
            profile: {},
            features: {},
            plan_redflags: {},
            providers: {},
            years: {}
            // savings: {},
            // plan_redflagCodes: {}
        };

        this.feeSortSource = {
            fee_sort: {}
        }

        this.exportSource = {
            export: {}
        }

        this._profile = new BehaviorSubject(this.dataSource.profile);
        this._features = new BehaviorSubject(this.dataSource.features);
        this._plan_redflags = new BehaviorSubject(this.dataSource.plan_redflags);
        this._providers = new BehaviorSubject(this.dataSource.providers);
        this._years = new BehaviorSubject(this.dataSource.years);
        // this._savings = new BehaviorSubject(this.dataSource.savings);
        this._export = new Subject();
        this._fee_sort = new Subject();
        // this._plan_redflagCodes = new BehaviorSubject(this.dataSource.plan_redflagCodes);
    }

    /************ Export ************/
    public get $export(): Observable<any> {
        return this._export.asObservable();
    }

    public get export(): any {
        return this.exportSource.export;
    }

    public set export(value: any) {
        this.exportSource.export = value;
        this._export.next(Object.assign({}, value));
    }

    /************ fee tab sorting ************/
    public get $fee_sort(): Observable<any> {
        return this._fee_sort.asObservable();
    }

    public get fee_sort(): any {
        return this.feeSortSource.fee_sort;
    }

    public set fee_sort(value: any) {
        this.feeSortSource.fee_sort = value;
        this._fee_sort.next(Object.assign({}, value));
    }

    /************ Profile ************/
    public get $profile(): Observable<any> {
        return this._profile.asObservable();
    }

    public get profile(): any {
        return this.dataSource.profile;
    }

    public set profile(value: any) {
        this.dataSource.profile = value;
        this._profile.next(Object.assign({}, value));
    }

    /************ Features ************/
    public get $features(): Observable<any> {
        return this._features.asObservable();
    }

    /************ Plan Redflags ************/
    public get $plan_redflags(): Observable<any> {
        return this._plan_redflags.asObservable();
    }

    /************ Providers ************/
    public get $providers(): Observable<any> {
        return this._providers.asObservable();
    }

    /************ Years ************/
    public get $years(): Observable<any> {
        return this._years.asObservable();
    }

    /************ Savings ************/
    /* public get $savings(): Observable<any> {
        return this._savings.asObservable();
    } */

    /************ Red Flag Codes ************/
    // public get $plan_redflagCodes(): Observable<any> {
    //     return this._plan_redflagCodes.asObservable();
    // }


    getProfile(id) {
        let service = this.http.get(`/api/profile/${id}`);
        service.subscribe((data: { data: Record<string, unknown> }) => {
            this.profile = data.data;
        });
        return service;
    }

    getChart(data) {
        return this.http.post('/api/profile/chart', data);
    }

    saveContact(data) {
        return this.http.post('/api/profile/contact', data);
    }

    /*
    getParticipationChart(data) {
        return this.http.get('/api/profile/benchmark-chart/participation/' + data);
    }
    getEmployerChart(data) {
        return this.http.get('/api/profile/benchmark-chart/employer/' + data);
    }
    getAvgEmployeeChart(data) {
        return this.http.get('/api/profile/benchmark-chart/avg-employee/' + data);
    }
    getRateReturnChart(data) {
        return this.http.get('/api/profile/benchmark-chart/rate-return/' + data);
    }
    getAdminFeesChart(data) {
        return this.http.get('/api/profile/benchmark-chart/admin-fees/' + data);
    }
    getPercentRetireesChart(data) {
        return this.http.get('/api/profile/benchmark-chart/percent-retirees/' + data);
    }
    */
    setActivityTrack(data) {
        return this.http.post('/api/profile/benchmark-chart/activity-track', data);
    }
    getAdvizorType(data) {
        return this.http.post('/api/advisor/advisor-type', data);
    }

    getBenchmarkChart(data) {
        return this.http.get('/api/profile/benchmark-chart/'+ data);
    }

    getDiversificationGrade(data) {
        return this.http.post('/api/profile/diversification-grade', data);
    }

    getFunds(data) {
        return this.http.post('/api/profile/funds', data);
    }

    getFees(data) {
        return this.http.post('/api/profile/fees', data);
    }

    setFeeOverRide(data: any) {
        return this.http.post('/api/profile/fee-over-ride', data);
    }

    getCalculateSaving(data) {
        return this.http.post('/api/profile/calculate-saving', data);
    }

    getContacts(data: any) {
        return this.http.get('/api/profile/contacts/' + data);
    }

    getFollow(data: any) {
        return this.http.post('/api/follow', data);
    }

    downloadFile(data: any) {
        return this.http.post('/api/download/files', data);
    }

    getPlaninfo(data: any) {
        return this.http.post('/api/profile/planinfo', data);
    }

    userFollow(data: any) {
        return this.http.post('/api/user-follow', data);
    }

    createReport(data: any) {
        return this.http.post('/api/profile/report', data);
    }

    getProfileLog(data: any, extraData: any) {
        return this.http.get('/api/search/profile-log/' + data, { params: { limit: extraData.per_page, page: extraData.current_page } });
    }

    getSaveSerach(extraData: any, id) {
        return this.http.get('/api/header/saved-search?', { params: { limit: extraData.per_page, page: extraData.current_page, id: id } });
    }

    deleteSaveSearch(searchid) {
        return this.http.delete('/api/header/saved-search/' + searchid);
    }

    unfollow(data) {
        return this.http.post('/api/user-unfollow', data);
    }

    getFollowedPlanList(extraData: any, data: any = {}, sortField: any = '', sortOrder: any = '') {
        return this.http.post('/api/followed-plan?', { with: data, status: extraData.status, search: extraData.search, user_filter: extraData.user_filter }, { params: { limit: extraData.per_page, page: extraData.current_page, sortField: sortField, sortOrder: sortOrder } });
    }

    followedPlanExport(data:any = {}) {
        // in data parameter there is status_type and user_id
        return this.http.post('/api/followed-plan-export', data);
    }

    getFollowedAdvizorList(extraData: any, data: any = {}, sortField: any = '', sortOrder: any = '') {
        return this.http.post('/api/followed-advizor?', { with: data }, { params: { limit: extraData.per_page, page: extraData.current_page, sortField: sortField, sortOrder: sortOrder, status: extraData.status, search: extraData.search, user_filter: extraData.user_filter } });
    }

    getRecommendedLeads(extraData: any) {
        return this.http.get('/api/recommended-lead', { params: { limit: extraData.per_page, page: extraData.current_page } });
    }

    getUserSettings(extraData: any) {
        return this.http.get('/api/user/settings', { params: extraData });
    }

    setUserSettings(data: any) {
        return this.http.post('/api/user/settings', data);
    }

    getSearchTicker(extraData: any) {
        return this.http.post('/api/header/search-ticker', extraData);
    }

    tickerAutocomplete(data) {
        return this.http.post('/api/profile/ticker-autocomplete', data);
    }

    addNote(data: any) {
        return this.http.post('/api/profile/note', data);
    }

    updateNote(data, id) {
        return this.http.put('/api/profile/note/' + id, data);
    }

    getNotes(ack_id) {
        return this.http.get('/api/profile/notes/' + ack_id);
    }

    getNotesById(extraData: any, ack_id:any){
        return this.http.get('/api/profile/getNotes/'+ ack_id, { params: { limit: extraData.per_page, page: extraData.current_page} });
    }

    getkeyContact(ack_id) {
        return this.http.get('/api/profile/key_contact/' + ack_id);
    }

    deleteNote(id) {
        return this.http.delete('/api/profile/note/' + id);
    }

    addTask(data: any) {
        return this.http.post('/api/profile/task', data);
    }

    updateTask(data, id) {
        return this.http.put('/api/profile/task/' + id, data);
    }

    getTasks(ack_id) {
        return this.http.get('/api/profile/tasks/' + ack_id);
    }

    getYears(id): Observable<Record<string, unknown>> {
        if (Object.keys(this.dataSource.years).length > 0) {
            return this._years.asObservable();
        }

        if (this.planYearRequest) {
            return this.planYearRequest;
        }

        this.planYearRequest = this.http.get<{ data: Record<string, unknown> }>(`/api/plan-years/${id}`).pipe(
            tap((response) => {
                this.dataSource.years = response.data;
                this._years.next(response.data);
            }),
            shareReplay(1),
            finalize(() => {
                this.planYearRequest = undefined;
            })
        );

        return this.planYearRequest;
    }

    deleteTask(id, type=null) {
        return this.http.delete('/api/profile/task/' + id, { params: { type: type }});
    }

    getFollowedPlanTask(extraData: { per_page: number; current_page: number; user_filter?: string } = { per_page: 10, current_page: 1 }, sortField: string = '', sortOrder: string = '') {
        return this.http.get('/api/followed-plan-tasks', { params: { limit: extraData.per_page, page: extraData.current_page, sortField: sortField, sortOrder: sortOrder, user_filter: extraData.user_filter } });
    }

    generateProfilePdf(data) {
        return this.http.post('/api/profile/profile-pdf', data);
    }

    getFeatures(id) {
        let service = this.http.get('/api/profile/' + id + '/features');
        service.subscribe((data: any) => {
            this.features = data.data;
            this._features.next(this.features);
        });
        return service;
    }

    getProfilePlanRedflags(id) {
        let service = this.http.get('/api/profile/' + id + '/red_flags');
        service.subscribe((data: any) => {
            this.plan_redflags = data.data;
            this._plan_redflags.next(this.plan_redflags);
        });
        return service;
    }

    getProviders(id) {
        let service = this.http.get('/api/profile/' + id + '/providers');
        service.subscribe((data: any) => {
            this.providers = data.data;
            this._providers.next(this.providers);
        });
        return service;
    }


    /* getSavings(id) {
        let service = this.http.get('/api/profile/' + id + '/savings');
        service.subscribe((data: any) => {
            this.savings = data.data;
            this._savings.next(this.savings);
        });
        return service;
    } */

    supportLog(message) {
        return this.http.post('/api/user/support', message);
    }

    generateAdvizorPdf(data) {
        return this.http.post('/api/advisor/advisor-pdf', data);
    }

    updateProviceStatus(data, id) {
        return this.http.put('/api/follow-status/' + id, data);
    }

    updateAdvisorStatus(data, id) {
        return this.http.put('/api/advisor-status/' + id, data);
    }

    followedAdvisorExport(args) {
        return this.http.get('/api/followed-advisor-export', {params: {status: args.status, user_filter: args.user_filter, search:args.search}});
    }

    adminAdvisor(data: { limit: number; page: number; search: string }) {
        return this.http.post('/api/admin/advisor', data);
    }

    adminPlan(data: { limit: number; page: number }) {
        return this.http.post('/api/admin/plan', data);
    }

    getUser(data) {
        return this.http.post('/api/user/users', data);
    }
    /* getProfilePlanRedflagCodes(id) {
        let service = this.http.get('/api/profile/' + id + '/red_flag_codes');
        service.subscribe((data: any) => {
            this.plan_redflagCodes = data;
            this._plan_redflagCodes.next(this.plan_redflagCodes);
        });
        return service;
    } */

    getProfileEmailActivity(ack_id) {
        return this.http.get('/api/automation/profile/'+ack_id);
    }

    updatetaskStatus(data, id) {
        return this.http.put('/api/task-status/' + id, data);
    }

    deleteContact(item) {
        return this.http.post('/api/profile/delete-contact', item);
    }

    deleteLogo(data) {
        return this.http.post('/api/profile/delete-logo', data);
    }

}
