import { Component, Input, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Dba, DbaContact } from '../../../../shared/interfaces/dba.interface';
import { AdvizorProService } from '../../../../shared/services/advizor-pro.service';

@Component({
  selector: 'firm-contact-popup',
  templateUrl: './firm-contact-popup.component.html',
  styleUrls: ['./firm-contact-popup.component.scss']
})

export class FirmContactPopupComponent implements OnInit, OnDestroy {
    @Input() data: any 
    @Input() key: string = null 
    @Input() state: string = null 
    @Input() contactsCounter: number = null
    @Input() advizorProService: AdvizorProService

    item: DbaContact
    firmItem: Dba  

    // contactsList : any[]
    // firmsList: any[]
    scale: number 

    isLoadingData = true
    isPopupOpen = false

    contactsPagination = { per_page: window.innerWidth< 1000 ? 1 : 2, current_page: 1, data: [], total: 0};
    // firmsPagination: any = { per_page: window.innerWidth< 1000 ? 1 : 2, current_page: 1, data: [], total: 0};

    resizeListener: () => void;
    resizeTimeout: ReturnType<typeof setTimeout> = null;


  constructor(){
    this.scale = 0.75;
  }

  async ngOnInit(){    
    this.isPopupOpen = false
    
    await this.mapQuery(this.key, this.state)
    this.isLoadingData = false

    this.resizeListener = this.onResize.bind(this);
    window.addEventListener('resize', this.resizeListener)
    
    // this.firmsList = this.data.filter(item => item.properties.type === 'firm'? true: false)
    // this.firmsPagination.total=this.firmsList.length
    // this.firmsPagination.data = this.firmsList.slice(0,this.contactsPagination.per_page)
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.resizeListener);
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);  // Clean up timeout
    }

    this.isPopupOpen = true;
  }

  @HostListener('window:resize', ['$event'])
  async onResize() {
    if (!this.isPopupOpen) {
      return;
    }
   
    this.isPopupOpen = false

    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }

    this.resizeTimeout = setTimeout(async() => {
      this.contactsPagination.per_page = window.innerWidth < 1000 ? 1 : 2;
      this.contactsPagination.current_page = 1;
      await this.mapQuery(this.key, this.state);
      this.isLoadingData = false
    }, 300);
  }


  async contactsHandlePagination($event){
    this.contactsPagination.current_page = parseInt($event)
  }
  
  /* firmsHandlePagination($event){
    this.firmsPagination.current_page = parseInt($event)
    const index = (this.firmsPagination.current_page - 1 ) * this.firmsPagination.per_page
    this.firmsPagination.data = this.firmsList.slice(index, index + this.firmsPagination.per_page)
  } */

  sliceContactsData(){
    return this.contactsPagination.data.slice((this.contactsPagination?.current_page -1) * this.contactsPagination.per_page,
        ((this.contactsPagination?.current_page -1)* this.contactsPagination.per_page) + this.contactsPagination.per_page
      )
  }

  async mapQuery(bounds: string | null = null, state: string | null = null): Promise<void> {
    if (!this.isPopupOpen) {
      this.isPopupOpen = true;
      return new Promise((resolve) => {
        let search_args = Object.assign({}, this.data);
        if (!search_args.firm_contains) {
          delete search_args.firm_contains_includes;
        }
        if (state) search_args.person_state = [state];

        let args = {
          limit: 100,
          page: 0,
          isMapSearch: true,
          searchData: search_args,
          searchMapData: {
            areAggregationNeeded: false,
            isDataNeeded: true,
            bounds: bounds ? { top_left: bounds, bottom_right: bounds } : null,
            precision: 1,
          },
          sortField: 'last_name',
          sortOrder: 1,
        };

        this.advizorProService.searchPerson(args).subscribe({
          next: (response: { data: { data: [], total: number } }) => {
            this.contactsPagination.data = response.data.data;
            this.contactsPagination.total = response.data.total > 100 ? 100 : response.data.total;
            this.isLoadingData = false;
            resolve();
          },
          error: (error: unknown) => {
            console.error('Error fetching data', error);
            this.isLoadingData = false;
            resolve();
          }
        });
      });
    }
  }

  toDbaFirm(firm){
    let phone_number=''
    if (firm.advizor_firm_addresses){
      try{
        const obj = JSON.parse(firm.advizor_firm_addresses);
        phone_number= obj[0].phone? obj[0].phone: ''
      }catch{

      }
    }

    return {
      id: firm.id ? firm.id : -1,
      firm_crd: firm.crd ? firm.crd : null,
      name: firm.firm_name ? firm.firm_name : null,
      website: firm.website ? firm.website : null,
      number_of_employees: firm.total_reps ? firm.total_reps : null,
      team_website: firm.team_website ? firm.team_website : null,
      created_at: firm.created_at ? firm.created_at : null,
      updated_at: firm.updated_at ? firm.updated_at : null,
      domain: firm.domain ? firm.domain : null,
      phone: phone_number,
      longitude: firm.longitude ? firm.longitude : null,
      latitude: firm.latitude ? firm.latitude : null,
      address: firm.addressString ? firm.addressString : null,
      city: firm.city ? firm.city : null,
      state: firm.state ? firm.state : null,
      zip: firm.zip ? firm.zip : null,
      email: firm.email ? firm.email : 'none',
      deleted_at: firm.deleted_at ? firm.deleted_at : null,
      photos: firm.photos ? firm.photos : []
    };
  }

  toDbaContact(contact){ 
    const extractFirm = () =>{
      for (let typeKey of Object.keys(contact.firms)){
        for (let firm of contact.firms[typeKey]){
          if (firm.name != null && firm.name != undefined)
            return {name: firm.name, crd: firm.id, type: typeKey}
        }
      }  
      return null
    } 
    
    const firmUrlBuilder = (firmType?: string | null, firmCrd?: string | null)=>{
      if (firmCrd === null)
        return null
      switch (firmType){
        case 'fo_ria':
        case 'fo':
          return '/family/firm/' + firmCrd
        case 'bt_bd':
        case 'bt_dual':
        case 'bt_ria':
        case 'bt':
          return '/bank/firm/' + firmCrd
        default:
          return '/advisor/firm/' + firmCrd
        
      }
    }
    const extractLocationInformation = (addresses) => {
      if (addresses?.length > 0 ){  
        for (const addrs of contact.addresses){
          return  { 
            address: addrs?.address_1 ? addrs?.address_1 : addrs?.address_2, 
            state: addrs?.state, 
            city: addrs?.city,
            zip: addrs?.zip
          } 
        } 
      }
      return null 
    }

    const firminfo = extractFirm()
    const firmUrl = firmUrlBuilder(firminfo?.type, firminfo?.crd) 
    const location = extractLocationInformation (contact.addresses) 
    
    return {
      //firm type 
      bt: contact?.bt ? contact.bt: null, 
      insurance: contact?.insurance ? contact.insurance: null,
      advizor: contact?.advizor ? contact.advizor : null,
      broker: contact?.broker ? contact.broker : null,
      fo: contact?.fo ? contact.fo : null, 
      fo_id: contact?.fo_id ? contact.fo_id : null, 
      bt_id: contact?.bt_id ? contact.bt_id : null, 
      dc: contact?.dc ? contact.dc : null, 
      db: contact?.db ? contact.db : null, 
      //location info
      address: location?.address,
      state: location?.state,
      city: location?.city, 
      zip: location?.zip,
      //person data
      crd: contact?.crd ? contact.crd : null,
      email: contact?.email ? contact.email : null, 
      first_name: contact?.first_name ? contact.first_name : null,
      firm_name: firminfo?.name, 
      firm_url:  firmUrl,
      middle_name: contact?.middle_name ? contact.middle_name : null,
      last_name: contact?.last_name ? contact.last_name : null,
      bio_url: contact?.bio_url ? contact.bio_url : null,
      linkedin: contact?.linkedin ? contact.linkedin : null,
      person_crd: contact?.crd ? contact.crd : null,
      phone: contact?.phone ? contact.phone : null,
      team_website: contact?.team_website ? contact.team_website : null,
      title: contact?.title ? contact.title : null,
      website: contact?.website ? contact.website : null,
      personal_email: contact?.personal_email ? contact.personal_email : null,
      photo_url: contact?.photo_url ? contact.photo_url : null,
      score: contact.score ? contact.score: null 
    };
  } 
}
