import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from '../@pages/components/message/message.service';
import { environment } from '../../environments/environment';

@Injectable()
export class Helper {

    constructor(
        private router: Router,
        private _notification: MessageService
    ) { }

    loading(status: boolean) {
        if (status) {
            //this.completeProgress();
            this.startProgress();
        } else {
            this.completeProgress();
        }
    }

    apiUrl(path?: string) {
        return environment.baseUrl + path;
    }

    successMessage(data: any, message?: any, duration?: number) {
        if (data && data.message != undefined) {
            this.addToast(data.message, "Success!", 'success', duration);
        } else {
            this.addToast(message, "Success!", 'success', duration);
        }
    }

    errorMessage(error: any, default_message?: any, duration?: number) {
        if (error.message == "token_invalid" || error.message == "token_expired") {
            this.addToast('Login Expired.', "Oops!", 'error', duration);
            this.router.navigate(['/login']);
            return;
        }
        if (error == "" || error == null) {
            this.addToast(default_message, "Oops!", 'error', duration);
            return;
        }
        if (error.json != undefined) {
            error = error.json();
        } else {
            error = error;
        }

        let all_errors: any = {};
        let message: any = "";
        if (error.errors != undefined) {
            if (this.isJsObject(error.errors)) {
                all_errors = error.errors;
                message = [];
                Object.keys(all_errors).map((key) => {
                    for (let er of all_errors[key]) {
                        message.push(er);
                    }
                });
                message = message.join('<br>');
            } else {
                message = error.errors
            }
            switch (error.type) {
                case "expired":
                    this.addToast(message, "Oops!", 'error', duration);
                    break;

                default:
                    this.addToast(message, "Oops!", 'error', duration);
                    break;
            }
        } else {
            if (error.error.errors != undefined || error.error.errors != null) {
                if (this.isJsObject(error.error.errors)) {
                    all_errors = error.error.errors;
                    message = [];
                    Object.keys(all_errors).map((key) => {
                        for (let er of all_errors[key]) {
                            message.push(er);
                        }
                    });
                    message = message.join('<br>');
                } else {
                    message = error.error.errors
                }
                switch (error.type) {
                    case "expired":
                        this.addToast(message, "Oops!", 'error', duration);
                        break;

                    default:
                        this.addToast(message, "Oops!", 'error', duration);
                        break;
                }
            } else if (error.error != undefined || error.error != null) {
                this.addToast(error.error, "Oops!", 'error', duration);
            } else {
                this.addToast(default_message, "Oops!", 'error', duration);
            }
        }

        if (error.code == 404) {
            this.router.navigate(['/404']);
        }
    }


    objectToParams(object: any): string {
        return Object.keys(object).map((key) => {
            if (this.isJsObject(object[key])) {
                return this.subObjectToParams(encodeURIComponent(key), object[key])
            } else {
                if (object[key] != null)
                    return `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`;
                else
                    return `${encodeURIComponent(key)}=`;
            }
        }
        ).join('&');
    }

    subObjectToParams(key: any, object: any): string {
        return Object.keys(object).map((childKey) => {
            if (this.isJsObject(object[childKey]))
                return this.subObjectToParams(`${key}[${encodeURIComponent(childKey)}]`, object[childKey]);
            else
                return `${key}[${encodeURIComponent(childKey)}]=${encodeURIComponent(object[childKey])}`;
        }
        ).join('&');
    }

    isJsObject(object: any) {
        let type: string = typeof (object);
        return (type == 'object' || type == 'Array') && type != null;
    }

    addToast(msg: any, title: any, type: any, duration: number = 50000) {

        this._notification.create(
            type,
            msg,
            {
                Position: "top-right",
                Style: "simple",
                Duration: duration
            }
        );
    }

    setProgres() {
        //this.slimLoader.progress = status;
    }
    startProgress() {
        //this.slimLoader.start();
    }
    completeProgress() {
        //this.slimLoader.complete();
    }
    stopProgress() {
        //this.slimLoader.stop();
    }
    resetProgress() {
        //this.slimLoader.reset();
    }
    incrementProgress() {
        //this.slimLoader.progress++;
    }

    getCookie(key: string) {
        if (localStorage.getItem(key))
            return JSON.parse(localStorage.getItem(key));
        else {
            if (key == 'advizorSearchData')
                return {};
            else
                return { filing: true };
        }
    }

    setCookie(key: string, Value: any) {
        localStorage.setItem(key, JSON.stringify(Value));
        return this.getCookie(key);
    }

    removeCookie(key: string) {
        localStorage.removeItem(key);
    }
}
